body {
  font-family: sans-serif;
}
.maps-container {
  position: relative;
}

#map-interactive-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0.75;
}

#map-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0.5;
}

#progress-toast {
  position: absolute;
  top: 10px;
  right: 10px;
}
